<template>

    <div class="wrapper -mi-perfil">
        <section class="encabezado -red-mentores">
            <h1></h1>
        </section>

        <template v-if="user">

            <section class="mi-perfil-intro" v-if="user">
                <div class="container row-start">
                    <div class="imagen" :style="`background:url(${user.image_url}) center/cover no-repeat;`"></div>
                    <div class="texto">
                        <h2>{{user.name}} {{user.surname}} {{user.surname_2}} <a :disabled="!user.profesional_linkedin"
                                :href="user.profesional_linkedin" target="_blank" class="linkedin"><i
                                    class="fa fa-linkedin"></i></a></h2>
                        <p v-if="user.profesional_current"><strong>Cargo actual:</strong> {{user.profesional_current}}
                        </p>
                        <p v-if="user.profesional_current_company"><strong>Empresa actual:</strong>
                            {{user.profesional_current_company}}</p>
                        <p><strong>Ubicación:</strong> {{user.town}}<template v-if="user.town">,</template>
                            {{user.province}}<template v-if="user.province">,</template> {{user.country}}</p>
                    </div>
                </div>
            </section>

            <div class="perfil-body container" v-if="user">

                <section class="perfil-module" v-if="$route.path.includes('mentee')">
                    <h2>Información personal</h2>
                    <div class="dato -middle" v-if="user.email">
                        <p><strong>Email Personal: </strong>
                            <a v-if="user.email"
                                :href="'mailto:'+user.email">{{user.email}} </a>
                            
                        </p>
                    </div>

                    <div class="dato -middle" v-if="user.email_internal">
                        <p><strong>Email UC3M: </strong>
                            <a v-if="user.email_internal"
                                :href="'mailto:'+user.email_internal">{{user.email_internal}} </a>
                            
                        </p>
                    </div>
    
                    <div class="dato -middle" v-if="user.phone">
                        <p>
                            <strong>Teléfono:</strong> <a v-if="user.phone"
                                :href="'tel:'+user.phone">{{user.phone}}</a>
                            
                        </p>
                    </div>
                </section>

                <section class="perfil-module">
                    <h2>Información profesional</h2>

                    <div class="dato" v-if="$route.path.includes('mentor')">
                        <p>
                            <strong>Reseña profesional:</strong>
                            <template v-if="user.profesional_resumen">
                                {{user.profesional_resumen}}
                            </template>
                            <template v-else>
                                Sin definir
                            </template>
                        </p>
                    </div>

                    <div class="dato" v-else>
                        <p>
                            <strong>Motivación para la participación:</strong>
                            <template v-if="user.preferences_motivation">
                                {{user.preferences_motivation}}
                            </template>
                            <template v-else>
                                Sin definir
                            </template>
                        </p>
                    </div>

                    <legend>Experiencia laboral</legend>

                    <profesional-el :key="key" :id="key" :values="n" v-for="(n,key) in user.profesional"
                        @reload="setData"></profesional-el>

                    <div class="dato" v-if="user.profesional.length == 0">
                        <p>Sin definir</p>
                    </div>
                </section>

                <section class="perfil-module">
                    <h2>Información académica</h2>

                    <div class="dato">
                        <p>
                            <strong>Egresado/a UC3M:</strong>
                            {{(user.old_alumn) ? 'Sí' : 'No'}}
                        </p>
                    </div>

                    <template v-if="user.academics.length != 0">
                        <academico-el :key="key" :id="key" :values="n" v-for="(n,key) in user.academics"></academico-el>
                    </template>
                    <p v-else>Sin definir</p>
                </section>

                <section class="perfil-module row-start">
                    <h2>Intereses o preferencias de Mentorías</h2>

                    <div class="dato -middle">
                        <p v-if="user.area.length != 0">
                            <strong>Áreas profesionales:</strong>
                            <template v-for="(n,key) in user.area">
                                {{n}}<template v-if="key != user.area.length-1">;</template>
                            </template>
                        </p>
                        <p v-else>
                            <strong>Áreas profesionales:</strong>
                            Sin definir
                        </p>
                    </div>

                    <div class="dato -middle">
                        <p v-if="user.sector.length != 0">
                            <strong>Sectores:</strong>
                            <template v-for="(n,key) in user.sector">
                                {{n}}<template v-if="key != user.sector.length-1">;</template>
                            </template>
                        </p>
                        <p v-else>
                            <strong>Sectores:</strong>
                            Sin definir
                        </p>

                    </div>


                    <div class="dato -middle">
                        <p><strong>Modalidad de mentoría:</strong>
                            {{domains.preferences[user.preferences_participation]}}</p>
                    </div>


                    <div class="dato -middle">
                        <p v-if="user.language.length != 0">
                            <strong>Idiomas:</strong>
                            <template v-for="(n,key) in user.language">
                                {{n}}<template v-if="key != user.language.length-1">;</template>
                            </template>
                        </p>
                        <p v-else>
                            <strong>Idiomas:</strong>
                            Sin definir
                        </p>
                    </div>

                    <div class="dato -middle">
                        <p v-if="user.ability.length != 0">
                            <strong>Habilidades:</strong>
                            <template v-for="(n,key) in user.ability">
                                {{n}}<template v-if="key != user.ability.length-1">;</template>
                            </template>
                        </p>
                        <p v-else>
                            <strong>Habilidades:</strong>
                            Sin definir
                        </p>
                    </div>
                    <div class="dato -middle">
                        <p v-if="user.observations_other_abilities">
                            <strong>Observaciones/Otras habilidades:</strong>
                            <template v-for="(n,key) in user.observations_other_abilities">
                                {{n}}<template v-if="key != user.observations_other_abilities.length-1">;</template>
                            </template>
                        </p>
                        <p v-else>
                            <strong>Habilidades:</strong>
                            Sin definir
                        </p>
                    </div>

                </section>




            </div>

        </template>

        <div class="loading" v-else></div>

    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';

    import academico from '@/components/mi-perfil/academico-el';

    import profesional from '@/components/mi-perfil/profesional-el';


    export default {
        name: 'ficha-mentor',
        mounted() {
            this.setData();
        },
        data: () => ({
            user: '',
        }),
        computed: {
            ...mapGetters({
                domains: 'getDomains'
            })
        },
        methods: {
            ...mapActions(['requestMentor', 'requestMentee']),
            setData() {

                if (this.$route.fullPath.includes('mentor')) {
                    this.requestMentor(this.$route.params.id).then(r => {
                        this.user = r;
                    })
                } else {
                    this.requestMentee(this.$route.params.id).then(r => {
                        this.user = r;
                    })
                }
            }
        },
        components: {
            'academico-el': academico,
            'profesional-el': profesional,
        },

    }
</script>


<style lang="scss" scoped>
    .perfil-module h2 {
        margin-bottom: 20px;
    }
</style>